export const environment = {
   production: false,
   version: '3.29.30',
   FirebaseFunctionUrlCloud: 'https://us-central1-omni2100.cloudfunctions.net',
   FirebaseFunctionUrlLocal: 'http://localhost:5000/omni2100/us-central1',
   googleAPIKey: 'AIzaSyA4PibHw2Uk57pc4iB_wwWcNFgqnnivV-g',
   googleAPIKey_tz: 'AIzaSyDU-WspyWCE-HODAYVGUXp-U4w6TK4XXeg',
   firebase: {
      apiKey: 'AIzaSyDV5tDZ2VVCb47eqoG-Qi6D7v1KLSzHW3s',
      authDomain: 'omni2100.firebaseapp.com',
      databaseURL: 'https://omni2100.firebaseio.com',
      projectId: 'omni2100',
      storageBucket: 'omni2100.appspot.com',
      messagingSenderId: 'omni2100.appspot.com',
   },
   APIS: {
      USERS: 'Users_Dev',
      CUSTOMERPORTALS: 'CustomerPortals_Dev',
      CUSTOMERS: 'Customers_Dev',
      ZONES: 'Zones_Dev',
      SENSORS: 'Sensors_Dev',
      STAFFINFO: 'StaffInfo_Dev',
      SENSORTYPES: 'SensorTypes_Dev',
      SENSORMODELS: 'SensorModels_Dev',
      SENSORDATA: 'SensorData_Dev',
      SENSORDEVICES: 'SensorDevices_Dev',
      SENSORCALIBRATION: 'SensorCalibration_Dev',
      SENSORCONFIGS: 'SensorConfigs_Dev',
      ANAYLYTICALDATA: 'AnalyticalData_Dev',
      PROCESSEDDATA: 'ProcessedData_Dev',
      SENSORCSVS: 'SensorCsvs_Dev',
      RAWDATA: 'RawData_Dev',
      RUN: 'Run_Dev',
      DEBUGDATA: 'DebugData_Dev',
      CONFIGURATIONS: 'Configurations_Dev',
      CRONJOBTESTSENSOR: 'CronJobTestSensor_Dev',
      CALIBRATIONDATA: 'CalibrationData_Dev',
      CUSTOMERCONTACTGROUPS: 'CustomerContactGroups_Dev'
   },
   Upload: {
      logoFolder: 'logo_dev',
      SENSORCOMFIGS: 'SensorConfigs_Dev',
      PROCESSCSVS: 'ProcessCSVs_Dev',
   },
   SystemType: 'dev',
   API_BASE_URL: 'https://omni-api.omniscent.com',
   API_BASE_URL_EXT: '/v3/query'
};
